
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.option :global(.mantine-RadioIndicator-indicator) + span {
  font-size: $font-sub;
}

.form {
  :global(.mantine-TextInput-label),
  :global(.mantine-NativeSelect-label) {
    font-size: $font-x-sub;
  }

  :global(.mantine-NativeSelect-input),
  :global(.mantine-NativeSelect-section) {
    border-color: var(--input-bd);
    font-weight: normal;
  }
}

.alert {
  :global(.mantine-Alert-wrapper) {
    align-items: center;
  }

  :global(.mantine-Alert-message) {
    font-size: $font-small;
  }
}

.upcomingChargesNotice {
  background-color: $color-indonesia-whisper;
  padding: var(--mantine-spacing-xs);
  font-size: $font-small;
  margin: 0 calc(var(--mantine-spacing-xs) * -1) calc(var(--mantine-spacing-xs) * -1);
}
