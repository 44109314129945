
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.skippedWrapper {
  width: 100%;
}

.alert {
  margin-top: 0 !important;
}
